import { Dialog, notifyError, notifySuccess } from '@/components'
import UnitSelect from '@/modules/add-new-unit/components/UnitSelect/UnitSelect'
import {
  useAddNewUnitMutation,
  useGetNewUnitQuery,
} from '@/modules/add-new-unit/services/newUnitService'
import { AddNewUnitRes } from '@/modules/add-new-unit/types/newUnitType'
import { UnitSearchReq } from '@/modules/devices-list/types/newUnitsType'
import { useGetUnitsHomeMapMutation } from '@/modules/maps/services/homeMapService'
import { useGetUnitsTypesListQuery } from '@/services/unitsListService'
import { timeout } from '@/utils'
import cn from '@/utils/cn'
import { useChangeSet } from '@/utils/useChangeSet'
import { yupResolver } from '@hookform/resolvers/yup'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { LoadingButton } from '@mui/lab'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../Input/Input'
import { SmartDevicesIds } from '@/types/enums'
import MapNew from '@/components/Organisms/Map/Map'

const s = cn('add-new-unit')

interface IUnitData {
  setLoader: Dispatch<SetStateAction<boolean>>
}

const center = [47.2313, 39.7233]

const pointsIfEmpty = [
  [48.024402067130715, 39.85466330972504],
  [46.780699672601415, 39.807971415195674],
]

const defaultPoint = [47.06587193746529, 39.435380396518724]

export interface IUnitsFields {
  name: string
  address: string
  systemId: number | ''
  powerTypeId: number
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Имя не может быть пустым')
    .trim('Имя не может быть пустым')
    .max(30, 'Поле должно содержать не более 30 символов'),
  address: Yup.string()
    .required('Адрес не может быть пустым')
    .trim('Адрес не может быть пустым')
    .max(250, 'Поле должно содержать не более 250 символов'),
  systemId: Yup.number()
    .required('Выберите систему')
    .typeError('Выберите систему'),
})

const UnitData: FC<IUnitData> = ({ setLoader }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data } = useGetNewUnitQuery({ id: Number(id) })
  const { data: unitsTypesData, isLoading: isLoadingUnitsTypesList } =
    useGetUnitsTypesListQuery({ isNew: true, isAll: true, isSmart: true })
  const [addUnitService] = useAddNewUnitMutation()
  const [getDataUnits, { data: locations }] = useGetUnitsHomeMapMutation()

  const [points, setPoints] = useState<number[][]>([])

  const onGetUnits = async () => {
    try {
      const usersSearchData: Omit<UnitSearchReq, 'page'> = {
        value: '',
        deviceTypeFilter: [0],
        deviceStateFilter: [0],
        favoriteId: 0,
      }
      const res = await getDataUnits(usersSearchData).unwrap()
      if (res.success) {
        const units =
          res.data.data.length === 0
            ? pointsIfEmpty
            : res.data.data.map((unit) => unit.coordinates)
        setPoints(() => units)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits().then()
  }, [])

  const devicesList = unitsTypesData?.data.filter((device) =>
    SmartDevicesIds.includes(device.id)
  )

  const [openDialogSave, setOpenDialogSave] = useState(false)
  const [newCoords, setNewCoords] = useState<number[]>(defaultPoint)
  const refMark = useRef<any>(null)

  const defaultValues: IUnitsFields = {
    name: '',
    address: '',
    systemId: '',
    powerTypeId: 1,
  }

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState,
    watch,
    getValues,
    setValue,
  } = useForm<IUnitsFields>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    criteriaMode: 'all',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    reset({
      name: data?.data?.name,
      address: '',
      systemId: '',
    })
  }, [id, data])

  const onSaveBtnClick = () => {
    trigger().then(() => {
      if (
        !getValues('name') ||
        !getValues('address') ||
        !getValues('systemId')
      ) {
        return
      }
      if (
        !formState.errors.name &&
        !formState.errors.address &&
        !formState.errors.systemId
      ) {
        setOpenDialogSave(true)
      }
    })
  }

  const onSubmit = async (dataForm: IUnitsFields) => {
    try {
      setOpenDialogSave(false)
      setLoader(true)
      const newUnit: AddNewUnitRes = {
        id: Number(id),
        name: dataForm.name,
        address: dataForm.address,
        typeId: dataForm.systemId,
        powerTypeId: dataForm.powerTypeId,
        coordinates: JSON.stringify(newCoords),
        imei: data?.data?.imei ?? '',
      }
      const res = await addUnitService(newUnit).unwrap()
      if (res.success) {
        notifySuccess('Новое устройство успешно добавлено!')
        await timeout(1000)
        navigate('/new-units')
      }
    } catch (e) {
    } finally {
      setLoader(false)
    }
  }

  useChangeSet(() => {
    if (watch('name').length) {
      trigger('name')
    }
    if (watch('address').length) {
      trigger('address')
    }
    if (watch('systemId')) {
      trigger('systemId')
    }
  }, [watch('name'), watch('address'), watch('systemId')])

  useChangeSet(() => {
    setValue('name', `${watch('name')} `, { shouldDirty: true })
  }, [newCoords])
  const handleChangeCoords = (coords: number[]) => {
    setNewCoords(coords)
  }
  return (
    <Box
      position='relative'
      px={{ xss: 0, md: 2 }}
      pb={2}
      height='100%'
      sx={{ width: '100%', pb: 2 }}
    >
      <Dialog
        message='Изменения будут сохранены, продолжить?'
        open={openDialogSave}
        handleClose={() => setOpenDialogSave(false)}
        handleAgree={() => {
          if (Object.keys(formState.errors).length >= 1) {
            notifyError('Исправте ошибки в форме')
          }
          handleSubmit(onSubmit)()
        }}
      />
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid
          container
          rowSpacing={2}
          mb={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3, xl: 5, xxl: 6 }}
        >
          <Grid
            item
            xss={12}
            lg={6}
          >
            <Stack
              direction='row'
              alignItems='flex-start'
            >
              <IconButton
                style={{ position: 'relative', left: -10 }}
                onClick={() => navigate(-1)}
              >
                <KeyboardBackspaceIcon />
              </IconButton>
              <Stack>
                <Stack
                  direction='column'
                  gap={0}
                >
                  {data?.data?.name ? (
                    <Typography variant='h6'>
                      Устройство {data?.data?.name}
                    </Typography>
                  ) : null}
                  <Typography variant='body2'>
                    {data?.data?.lastConnectionDate ? (
                      <>
                        подключено{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {data?.data?.lastConnectionDate}
                        </span>
                      </>
                    ) : null}
                    {data?.data?.ip ? (
                      <>
                        {' '}
                        с IP адреса{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {data?.data?.ip}
                        </span>
                      </>
                    ) : null}
                  </Typography>
                </Stack>
                <Stack
                  direction='column'
                  gap={0}
                  pt={4}
                >
                  {data?.data?.imei ? (
                    <Typography variant='body1'>
                      IMEI: {data?.data?.imei}
                    </Typography>
                  ) : null}
                  {data?.data?.iccid ? (
                    <Typography variant='body1'>
                      ICCID: {data?.data?.iccid}
                    </Typography>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          <Grid
            item
            xss={12}
            lg={6}
          >
            <Stack
              direction='column'
              gap={2}
            >
              <Input
                name='name'
                type='text'
                label='Наименование системы'
                control={control}
                //disabled={isLoadingUpdateOrganization}
              />
              <Input
                multiline
                minRows={2}
                maxRows={5}
                name='address'
                type='text'
                label='Адрес установки'
                control={control}
                //disabled={isLoadingUpdateOrganization}
              />

              <UnitSelect
                disabled={isLoadingUnitsTypesList}
                items={devicesList}
                name='systemId'
                label='Выберите систему'
                control={control}
              />
              <div
                style={{
                  display:
                    watch('systemId') === 1 || watch('systemId') === 2
                      ? 'block'
                      : 'none',
                }}
              >
                <UnitSelect
                  items={[
                    { id: 1, name: '12В' },
                    { id: 2, name: '24В' },
                    { id: 3, name: '220B' },
                  ]}
                  name='powerTypeId'
                  label='Выберите систему питания'
                  control={control}
                />
              </div>
            </Stack>
          </Grid>
        </Grid>
        <Box
          border='1px solid #0072BC'
          className={s('map-container')}
          flex={1}
        >
          <MapNew
            center={center}
            dataUnits={locations}
            setNewCoords={handleChangeCoords}
            newCoords={newCoords}
            draggable={true}
          />
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          gap={2}
          mt={2}
        >
          <LoadingButton
            loading={false}
            variant='outlined'
            onClick={onSaveBtnClick}
            disabled={
              watch('name')?.trim()?.length === 0 ||
              watch('address')?.trim()?.length === 0 ||
              watch('systemId') === '' ||
              newCoords === defaultPoint
            }
          >
            <span>Сохранить</span>
          </LoadingButton>
        </Box>
      </form>
    </Box>
  )
}

export default UnitData
