import TableCell from '@mui/material/TableCell'
import { Checkbox } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { OrganizationInList } from '@/modules/organization/types/organizationType'
import cn from '@/utils/cn'
import { useParams } from 'react-router-dom'
import {
  useAddUnitsMutation,
  useDeleteUnitsMutation,
} from '@/modules/organization/services/organizationService'
import { notifySuccess } from '@/components'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'

const s = cn('units')

interface UnitRowProps {
  unit: OrganizationInList
}

const UnitRow: FC<UnitRowProps> = ({ unit }) => {
  const { id } = useParams()

  const [selected, setSelected] = useState(false)
  const [loader, setLoader] = useState(false)

  const [addUnitsService] = useAddUnitsMutation()
  const [deleteUnitsService] = useDeleteUnitsMutation()

  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const onSelect = async (
    e: React.ChangeEvent<HTMLInputElement>,
    deviceId: number
  ) => {
    try {
      setSelected(() => e.target.checked)
      setLoader(true)
      if (e.target.checked) {
        const res = await addUnitsService({
          organizationId: Number(id),
          deviceIds: [deviceId],
        }).unwrap()
        if (res.success) {
          notifySuccess('Устройство добавлено')
        } else {
          setSelected(() => unit.selected)
        }
      } else {
        const res = await deleteUnitsService({
          organizationId: Number(id),
          deviceIds: [deviceId],
        }).unwrap()
        if (res.success) {
          notifySuccess('Устройство удалено')
        } else {
          setSelected(() => unit.selected)
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    setSelected(() => unit.selected)
  }, [unit])

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={unit.deviceId}
      className={s()}
      style={{ filter: loader ? 'blur(2px)' : 'unset' }}
    >
      {currentUserType === UserTypes.admin ? null : (
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            checked={selected}
            onChange={(e) => onSelect(e, unit.deviceId)}
          />
        </TableCell>
      )}
      <TableCell
        component='th'
        scope='row'
        sx={{ minWidth: 185 }}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <span>{unit.type}</span>
        <span style={{ fontWeight: 'bold' }}>{unit.name}</span>
      </TableCell>
      <TableCell
        align='right'
        sx={{ minWidth: 385 }}
      >
        {unit.address}
      </TableCell>
    </TableRow>
  )
}

export default UnitRow
