import { useActions, useAppSelector } from '@/hooks'
import Header from '@/modules/devices-list/components/Header/Header'
import { UnitSearchReq } from '@/modules/devices-list/types/newUnitsType'
import PlacemarkBalloon from '@/modules/maps/components/PlacemarkBalloon'
import {
  useGetDetailUnitMutation,
  useGetUnitsHomeMapMutation,
} from '@/modules/maps/services/homeMapService'
import { filterSystemSelector } from '@/store/filterSystemsSlise'
import { unitsSelector } from '@/store/unitsSlise'
import cn from '@/utils/cn'
import { Box, CircularProgress } from '@mui/material'
import {
  Clusterer,
  Map,
  TypeSelector,
  useYMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps'
import React, { useEffect, useRef, useState } from 'react'
import ymaps from 'yandex-maps'

const pointsIfEmpty = [
  [48.024402067130715, 39.85466330972504],
  [46.780699672601415, 39.807971415195674],
]

const s = cn('home-map')
const HomeMap = () => {
  const yamap = useRef<any>()

  const units = useAppSelector(unitsSelector)

  const [getUnitService, { isLoading, data }] = useGetUnitsHomeMapMutation()
  const { filter, searchValue, isSearch, favoriteId, deviceStateFilter } =
    useAppSelector(filterSystemSelector)
  const { setSearchValue, setSelectedTab } = useActions()

  const [points, setPoints] = useState<number[][]>([])
  const [center, setCenter] = useState<number[]>([47.2313, 39.7233])
  const [openBalloon, setOpenBalloon] = React.useState(false)
  const [isDeleteCluster, setIsDeleteCluster] = React.useState(false)

  const onGetUnits = async () => {
    try {
      const usersSearchData: Omit<UnitSearchReq, 'page'> = {
        value: !searchValue.trim().length ? '' : searchValue,
        deviceTypeFilter: filter,
        deviceStateFilter: deviceStateFilter,
        favoriteId: favoriteId,
      }
      const res = await getUnitService(usersSearchData).unwrap()
      if (res.success) {
        const units =
          res?.data?.data?.length === 0
            ? pointsIfEmpty
            : res?.data?.data?.map((unit) => unit.coordinates)
        setPoints(() => units)
      }
      if (!searchValue.trim().length) {
        setSearchValue('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits().then()
    setSelectedTab(0)
  }, [isSearch, deviceStateFilter])

  const closeBalloon = () => {
    const close: any = document.querySelector(
      'ymaps[class$="-balloon__close-button"]'
    )
    if (close != null) {
      close.click()
    }
  }

  const onSetBounds = (e: typeof ymaps) => {
    const bounds = e.util.bounds.fromPoints(points)
    yamap?.current?.setBounds(bounds, { checkZoomRange: true })
  }
  const map = useYMaps()

  useEffect(() => {
    const bounds = map?.util.bounds.fromPoints(points)
    yamap?.current?.setBounds(bounds, { checkZoomRange: true })
  }, [data, points])

  const [getDetailUnit] = useGetDetailUnitMutation()
  const onLoadData = async (id: number) => {
    try {
      await getDetailUnit(id).unwrap()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <CircularProgress
        className={isLoading ? s('show-loader') : s('hide-loader')}
      />
      <Box className={s({ loading: isLoading })}>
        <Header isMap />
        <Map
          instanceRef={yamap}
          state={{
            center: center,
            zoom: 9,
            controls: [],
          }}
          options={{
            yandexMapDisablePoiInteractivity: true,
          }}
          onClick={closeBalloon}
          modules={['control.ZoomControl', 'util.bounds']}
          className='map_container'
          onLoad={onSetBounds}
          onBoundsChange={(e: any) => {
            if (e.originalEvent.newZoom > 20) {
              setIsDeleteCluster(true)
            } else {
              setIsDeleteCluster(false)
            }
          }}
        >
          <ZoomControl
            options={{
              position: {
                right: 10,
                left: 'auto',
                top: (document.documentElement.scrollHeight - 206) / 2,
                bottom: 'auto',
              },
              // @ts-ignore
              float: 'right',
            }}
          />
          <TypeSelector
            options={{
              // @ts-ignore
              panoramasItemMode: 'off',
            }}
          />
          {isDeleteCluster ? (
            <>
              {units?.data?.data?.map((unit, i) => {
                return (
                  <PlacemarkBalloon
                    key={i}
                    unit={unit}
                    yamap={yamap}
                  />
                )
              })}
            </>
          ) : (
            <Clusterer
              options={{
                preset: 'islands#invertedClusterIcons',
                groupByCoordinates: false,
              }}
              onBalloonClose={() => setOpenBalloon(false)}
              onBalloonOpen={(e: any) => {
                const objects = e.get('cluster')?.getGeoObjects()
                const ids: number[] = []
                objects?.forEach((object: any) => {
                  const id = object.properties.get('id')
                  ids.push(id)
                })
                ids.forEach((id) => onLoadData(id))
                setOpenBalloon(true)
              }}
            >
              {openBalloon ? (
                <>
                  {units?.data?.data?.map((unit, i) => {
                    return (
                      <PlacemarkBalloon
                        key={i}
                        unit={unit}
                        yamap={yamap}
                      />
                    )
                  })}
                </>
              ) : (
                <>
                  {data?.data?.data?.map((unit, i) => {
                    return (
                      <PlacemarkBalloon
                        key={i}
                        unit={unit}
                        yamap={yamap}
                      />
                    )
                  })}
                </>
              )}
            </Clusterer>
          )}
        </Map>
      </Box>
    </>
  )
}

export default HomeMap
