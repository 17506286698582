import greenLocation from '@/assets/img/location-green.png'
import redLocation from '@/assets/img/location-red.png'
import yellowLocation from '@/assets/img/location-yellow.png'
import trafficLightIcon from '@/assets/img/traficLight.png'
import rhombusIcon from '@/assets/img/rhombus.png'
import { DeviceType } from '@/types/enums'
import { useActions } from '@/hooks'
import {
  UnitsMapTypeRes,
  UnitsMapType,
} from '@/modules/maps-tank/types/unitsMapType'
import {
  Map,
  Placemark,
  SearchControl,
  TypeSelector,
  ZoomControl,
} from '@pbe/react-yandex-maps'
import React, { FC, memo, useRef } from 'react'

interface Props {
  center: number[]
  setNewCoords: (coords: number[]) => void
  dataUnits: UnitsMapTypeRes | undefined
  newCoords: number[]
  draggable: boolean
}

export const getIconForUnit = (unit: UnitsMapType): string => {
  let res = greenLocation
  const { state, typeId } = unit
  if (state === 1) res = greenLocation
  if (state === 2) res = yellowLocation
  if (state === 3) res = redLocation
  if (typeId === DeviceType.kao || typeId === DeviceType.ksodd)
    res = rhombusIcon
  if (typeId === DeviceType.trafficLight) res = trafficLightIcon
  return res
}

const MapFeatures: FC<Props> = ({
  center,
  setNewCoords,
  dataUnits,
  newCoords,
  draggable,
}) => {
  const { setIsChangeFeatures } = useActions()
  const refPlacemark = useRef<any>(null)

  const handleMapClick = (event: any) => {
    const clickedCoords = event.get('coords')
    setNewCoords(clickedCoords)
    setIsChangeFeatures(true)
  }

  const handlePlacemarkDragEnd = () => {
    const draggedCoords = refPlacemark.current.geometry?._coordinates
    if (draggedCoords) {
      setNewCoords(draggedCoords)
      setIsChangeFeatures(true)
    }
  }

  return (
    <Map
      state={{ center, zoom: 9, controls: [] }}
      options={{ yandexMapDisablePoiInteractivity: true }}
      modules={['control.ZoomControl']}
      width='100%'
      height='100%'
      onClick={handleMapClick}
    >
      <SearchControl options={{ float: 'left', noPlacemark: true }} />
      <ZoomControl
        options={{
          position: {
            right: 10,
            left: 'auto',
            top: (document.documentElement.scrollHeight - 530) / 2,
            bottom: 'auto',
          },
          // @ts-ignore
          float: 'right',
        }}
      />

      <TypeSelector
        options={{
          // @ts-ignore
          panoramasItemMode: 'off',
        }}
      />
      {dataUnits?.data?.data?.map((unit) => (
        <Placemark
          key={unit.id}
          defaultGeometry={unit.coordinates}
          options={{
            iconLayout: 'default#image',
            iconImageSize: [30, 30],
            iconImageHref: getIconForUnit(unit),
          }}
        />
      ))}
      <Placemark
        instanceRef={refPlacemark}
        onDragEnd={handlePlacemarkDragEnd}
        geometry={newCoords}
        options={{
          iconImageSize: [30, 30],
          draggable,
          preset: 'islands#greenIcon',
        }}
        properties={{ iconContent: '+' }}
      />
    </Map>
  )
}

export default memo(MapFeatures)
