import bg from '@/assets/img/bg1.jpg'
import { useActions, useAppSelector } from '@/hooks'
import Chart from '@/modules/device-module/components/chart/Chart'
import Description from '@/modules/device-module/components/description/Description'
import Features from '@/modules/device-module/components/features/Features'
import FeaturesPhoto from '@/modules/device-module/components/features/FeaturesPhoto'
import FeaturesVideo from '@/modules/device-module/components/features/FeaturesVideo'
import FeaturesDit from '@/modules/device-module/components/features/FeaturesDit'
import Gallery from '@/components/Organisms/Gallery'
import TableMeteo from '@/modules/device-module/components/meteo/TableMeteo'
import TableDevice from '@/modules/device-module/components/table/TableDevice'
import Terminal from '@/modules/device-module/components/terminal/Terminal'
import LazyVideoComponent from '@/modules/device-module/components/video'
import DIT from '@/pages/DIT'
import {
  useGetDeviceDetailIntervalQuery,
  useGetDeviceDetailQuery,
  useGetPhotosDetailQuery,
} from '@/modules/device-module/services/deviceService'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
import { Status } from '@/modules/device-module/types/deviceType'
import {
  filterSystemSelector,
  sidebarWithSelector,
} from '@/store/filterSystemsSlise'
import cn from '@/utils/cn'
import { Box, CircularProgress, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Actions from './components/actions/Actions'
import Events from './components/events/Events'
import Header from './components/header/Header'
import {
  stateDeviceSelectorFromDateEvents,
  stateDeviceSelectorToDate,
} from '@/modules/aqua-device-module/store/stateSlice'
import { DeviceType } from '@/types/enums'

const s = cn('device-module')
const DeviceModule = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const [loading, setLoading] = useState(false)
  const [skipFirstQuery, setSkipFirstQuery] = useState(true)
  const { setFooterCounters } = useActions()
  const { selectedTab, device, isOnChange } =
    useAppSelector(stateDeviceSelector)

  const { data, isLoading } = useGetDeviceDetailQuery(Number(id), {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    pollingInterval: 60000 * 3,
    skip: pathname.includes('photos') || isOnChange,
  })

  useGetDeviceDetailIntervalQuery(Number(id), {
    pollingInterval: 60000,
    skip: pathname.includes('photos') || skipFirstQuery,
  })

  const { data: photosData, isLoading: isLoadingPhotos } =
    useGetPhotosDetailQuery(Number(id), {
      skip: !pathname.includes('photos'),
      refetchOnMountOrArgChange: true,
    })
  useEffect(() => {
    const modules = data?.typeId === 4 ? device?.modulesMeteo : device?.modules
    if (modules) {
      setFooterCounters({
        totalErrors: modules.filter((item) => item.state === Status.error)
          .length,
        totalWarnings: modules.filter((item) => item.state === Status.warning)
          .length,
        totalServiceable: modules.filter((item) => item.state === Status.ok)
          .length,
      })
    }
  }, [device])

  const { isFullScreen: isFullScreenGlobal, isFullScreenDetail } =
    useAppSelector(filterSystemSelector)

  const sidebarWidth = useAppSelector(sidebarWithSelector)

  const {
    setSelected,
    setSelectedDevice,
    setCommands,
    setLastUpdatedDate,
    setTerminalHistoryNew,
  } = useActions()

  useEffect(() => {
    setSelectedDevice(true)
    setSelected([])
    setCommands([])
    setLastUpdatedDate(new Date('1900').toISOString())
    setTerminalHistoryNew([])
  }, [id])

  useEffect(() => {
    // Установить таймаут, чтобы переключить skipFirstQuery после некоторого времени
    const timer = setTimeout(() => {
      setSkipFirstQuery(false)
    }, 0) // Можно установить время задержки, если нужно

    // Очистка таймера, когда компонент будет размонтирован
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <CircularProgress
        color={isLoading || isLoadingPhotos ? 'white' : 'primary'}
        className={
          isLoading || loading || isLoadingPhotos
            ? s('show-loader')
            : s('hide-loader')
        }
      />
      <Box
        flex={1}
        sx={{ backgroundImage: `url(${bg})`, zIndex: 100 }}
        position='absolute'
        height='100%'
        width='100%'
        display={isLoading || isLoadingPhotos ? 'block' : 'none'}
        className={s('loader')}
      />
      <Box
        pt={{ md: 2 }}
        pb={0}
        sx={{
          paddingTop: 0,
          height: {
            xss:
              selectedTab === 0 &&
              !(
                data?.typeId === 7 ||
                data?.typeId === 8 ||
                photosData?.typeId === 7
              )
                ? '133%'
                : '100%',
            md: '100%',
          },
          width: {
            xss: 'unset',
            // mdx: `calc(100vw - ${sidebarWidth + 5}px)`,
          },
        }}
        display='flex'
        flexDirection='column'
        className={s({ loading: isLoading || loading || isLoadingPhotos })}
        position={
          isFullScreenGlobal || isFullScreenDetail ? 'unset' : 'relative'
        }
      >
        <Header />
        <Paper
          elevation={6}
          style={{ flex: 1 }}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.83)',
            width: '100%',
            display: isLoading || isLoadingPhotos ? 'none' : 'flex',
          }}
        >
          <Box
            flex={1}
            width='100%'
          >
            <Actions />
            <Box
              display='flex'
              flexDirection='column'
              height={{
                xss:
                  selectedTab === 3
                    ? '95%'
                    : selectedTab === 5
                      ? 'calc(100% - 65px)'
                      : 'calc(100% - 127px)',
                sm:
                  selectedTab === 3
                    ? '95%'
                    : selectedTab === 5
                      ? 'calc(100% - 65px)'
                      : 'calc(100% - 100px)',
                lg: 'calc(100% - 48px)',
              }}
            >
              {selectedTab === 0 ? (
                <>
                  {data?.typeId === 4 ? (
                    <TableMeteo setLoading={setLoading} />
                  ) : data?.typeId === 7 || photosData?.typeId === 7 ? (
                    <Gallery />
                  ) : data?.typeId === 8 ? (
                    <LazyVideoComponent />
                  ) : data?.typeId === 9 ? (
                    <DIT />
                  ) : (
                    <TableDevice setLoading={setLoading} />
                  )}
                  {data?.typeId === 7 ||
                  data?.typeId === 8 ||
                  data?.typeId === 9 ||
                  photosData?.typeId === 7 ? null : (
                    <Chart />
                  )}
                </>
              ) : selectedTab === 2 ? (
                <Events />
              ) : selectedTab === 3 ? (
                <Description />
              ) : selectedTab === 4 ? (
                data?.typeId === DeviceType.photo ||
                photosData?.typeId === DeviceType.photo ? (
                  <FeaturesPhoto />
                ) : data?.typeId === DeviceType.video ? (
                  <FeaturesVideo />
                ) : data?.typeId === DeviceType.dit ? (
                  <FeaturesDit />
                ) : (
                  <Features />
                )
              ) : selectedTab === 5 ? (
                <Terminal />
              ) : null}
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  )
}

export default DeviceModule
