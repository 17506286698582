import React, {
  MutableRefObject,
  type SyntheticEvent,
  useEffect,
  useState,
  useRef,
} from 'react'
import cn from 'classnames'
import ModalVariable from '@/components/Organisms/DIT/ModalVariable/ModalVariable'
import styles from './DITbuttons.module.scss'
import { ConstructorStyleItem } from '@/components/Organisms/DIT/types'
import IconTextRight from '@/components/Atoms/Icons/IconTextRight'
import IconTextLeft from '@/components/Atoms/Icons/IconTextLeft'
import IconTextCenter from '@/components/Atoms/Icons/IconTextCenter'
import IconTextBold from '@/components/Atoms/Icons/IconTextBold'
import IconTextUnderlined from '@/components/Atoms/Icons/IconTextUnderlined'
import DITTextarea from './DITTextarea'
import IconTextItalic from '@/components/Atoms/Icons/IconTextItalic'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
interface Props {
  activeItem: number
  activeSlide: number
  activeItemProps: ConstructorStyleItem
  handleConstructorProps: (style: ConstructorStyleItem) => void
}
type Variable = ConstructorStyleItem['parameters'][0]
const FONT_SIZES: number[] = []
for (let i = 12; i <= 40; i++) {
  if (!(i % 2)) {
    FONT_SIZES.push(i)
  }
}

const DITButtons = ({
  activeItemProps,
  activeItem,
  handleConstructorProps,
  activeSlide,
}: Props) => {
  const [text, setText] = useState<string>(activeItemProps.text)
  const [textBold, setTextBold] = useState<string>(activeItemProps.fontWeight)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const hasChanges = useAppSelector(ditSliceSelectors.stateHasChanges)
  const { ditSetUnsavedChanges } = useActions()
  const [fontSize, setFontSize] = useState(activeItemProps.fontSize)
  const [textPosition, setTextPosition] = useState(activeItemProps.textAlign)
  const [textDecoration, setTextDecoration] = useState(
    activeItemProps.textDecoration
  )
  const [fontStyle, setFontStyle] = useState(activeItemProps.fontStyle)
  const [color, setColor] = useState(activeItemProps.color)

  const [showModal, setShowModal] = React.useState(false)
  const [variables, setVariables] = React.useState<Variable[]>([])
  React.useEffect(() => {
    if (activeItemProps) {
      setVariables(activeItemProps.parameters)
      setText(activeItemProps.text)
      setTextBold(activeItemProps.fontWeight)
      setFontSize(activeItemProps.fontSize)
      setTextPosition(activeItemProps.textAlign)
      setColor(activeItemProps.color)
      setTextDecoration(activeItemProps.textDecoration)
      setFontStyle(activeItemProps.fontStyle)
    }
  }, [activeItem, hasChanges, activeSlide])

  const handleClickBold = () => {
    const newValue = textBold === 'normal' ? 'bold' : 'normal'
    activeItemProps.fontWeight = newValue
    handleConstructorProps({ ...activeItemProps })
    setTextBold(newValue)
    ditSetUnsavedChanges(true)
  }
  const handleFontSize = (e: any) => {
    activeItemProps.fontSize = e.target.value
    handleConstructorProps({ ...activeItemProps })
    setFontSize(e.target.value)
    ditSetUnsavedChanges(true)
  }
  const handleColor = (e: any) => {
    activeItemProps.color = e.target.value
    handleConstructorProps({ ...activeItemProps })
    setColor(e.target.value)
    ditSetUnsavedChanges(true)
  }
  const handleClickTextAlign = (
    position: ConstructorStyleItem['textAlign']
  ) => {
    activeItemProps.textAlign = position
    handleConstructorProps({ ...activeItemProps })
    setTextPosition(position)
    ditSetUnsavedChanges(true)
  }
  const handleClickUnderline = () => {
    const newValue = textDecoration === 'none' ? 'underline' : 'none'
    activeItemProps.textDecoration = newValue
    handleConstructorProps({ ...activeItemProps })
    setTextDecoration(newValue)
    ditSetUnsavedChanges(true)
  }
  const handleClickItalic = () => {
    const newValue = fontStyle === 'normal' ? 'italic' : 'normal'
    activeItemProps.fontStyle = newValue
    handleConstructorProps({ ...activeItemProps })
    setFontStyle(newValue)
    ditSetUnsavedChanges(true)
  }

  const handlerTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value
    // remove variables
    if (activeItemProps.parameters && activeItemProps.parameters.length) {
      const paramsLabels = activeItemProps.parameters.map((item) => item.label)
      paramsLabels.forEach((label) => {
        if (!newText.includes(label)) {
          const filteredVars = [...variables].filter(
            (item) => item.label !== label
          )
          setVariables(filteredVars)
          activeItemProps.parameters = filteredVars
        }
      })
    }
    activeItemProps.text = newText
    handleConstructorProps({ ...activeItemProps })
    setText(e.target.value)
    ditSetUnsavedChanges(true)
  }
  const handleAddVariable = () => {
    setShowModal(true)
  }

  const handleSetVariables = (variable: Variable, replace = '') => {
    const hasVar = variables.filter((item) => item.typeId === variable.typeId)
    const lastIndexes = hasVar
      .map((item) => Number(item.label.replace(/\D/g, '')))
      .sort((a, b) => a - b)
      .at(-1)
    if (hasVar) {
      variable.label =
        variable.label.slice(0, -1) +
        (lastIndexes ? lastIndexes + 1 : 1) +
        variable.label.slice(-1)
    }
    if (replace) {
      const copiedVariables = [...variables]

      const replaceIndex = variables.findIndex((item) => item.label === replace)
      copiedVariables[replaceIndex] = variable
      setVariables(copiedVariables)
      const newText = text
        .replace(variable.label, '')
        .replace(replace, variable.label)
      setText(newText)
      activeItemProps.text = newText
      activeItemProps.parameters = [...copiedVariables]
      handleConstructorProps({ ...activeItemProps })
    } else {
      const clearText = text.replace(variable.label, '')
      let newText = `${clearText} ${variable.label}`
      const target: number | undefined = textareaRef?.current?.selectionStart
      if (target !== null) {
        newText = `${clearText.slice(0, target)} ${variable.label} ${clearText.slice(
          target
        )}`
      }
      setVariables([...variables, variable])
      activeItemProps.text = newText
      activeItemProps.parameters = [...variables, variable]
      handleConstructorProps({ ...activeItemProps })
      setText(newText)
    }
    ditSetUnsavedChanges(true)
  }
  return (
    <div className={styles.Wrapper}>
      <ModalVariable
        showModal={showModal}
        variables={activeItemProps.parameters}
        setShowModal={setShowModal}
        setVariables={handleSetVariables}
      />
      <div className={styles.ButtonsWrapper}>
        <div className={styles.ButtonsRow}>
          <button
            onClick={handleClickBold}
            className={cn(styles.Button, {
              [styles.ButtonActive]: textBold !== 'normal',
            })}
          >
            <IconTextBold />
          </button>
          <button
            onClick={handleClickItalic}
            className={cn(styles.Button, {
              [styles.ButtonActive]: fontStyle !== 'normal',
            })}
          >
            <IconTextItalic />
          </button>
          <button
            onClick={handleClickUnderline}
            className={cn(styles.Button, {
              [styles.ButtonActive]: textDecoration !== 'none',
            })}
          >
            <IconTextUnderlined />
          </button>
          <select
            className={styles.Select}
            value={fontSize}
            onChange={handleFontSize}
          >
            {FONT_SIZES.map((s) => (
              <option
                key={s}
                value={s}
              >
                {s}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.ButtonsRow}>
          <button
            onClick={() => handleClickTextAlign('left')}
            className={cn(styles.Button, {
              [styles.ButtonActive]: textPosition === 'left',
            })}
          >
            <IconTextLeft />
          </button>
          <button
            onClick={() => handleClickTextAlign('center')}
            className={cn(styles.Button, {
              [styles.ButtonActive]: textPosition === 'center',
            })}
          >
            <IconTextCenter />
          </button>
          <button
            onClick={() => handleClickTextAlign('right')}
            className={cn(styles.Button, {
              [styles.ButtonActive]: textPosition === 'right',
            })}
          >
            <IconTextRight />
          </button>
          <input
            className={styles.ColorButton}
            type='color'
            disabled={true}
            onChange={handleColor}
            value={color}
          />
        </div>
        <div className={styles.ButtonsRow}>
          <button
            onClick={handleAddVariable}
            className={cn(styles.Button, styles.ButtonBlock)}
          >
            Добавить переменную
          </button>
        </div>
      </div>
      <div className={styles.TextAreaWrapper}>
        <DITTextarea
          ref={textareaRef}
          variables={variables.map((item) => item.label)}
          text={text}
          onTextChange={handlerTextChange}
        />
      </div>
    </div>
  )
}

export default DITButtons
