import { api } from '@/api'
import { TypeUnit, UnitSearchReq, UnitSearchRes } from '../types/newUnitsType'
import { setTypeUnits } from '@/store/filterSystemsSlise'
import { footerSliceActions } from '@/components/Organisms/Footer/slice'
import _ from 'lodash'

const newUnitsListService = api.injectEndpoints({
  endpoints: (build) => ({
    getUnitsList: build.mutation<UnitSearchRes, UnitSearchReq>({
      query: (body) => ({
        url: '/devices/search',
        method: 'POST',
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const {
            totalErrors,
            totalModulesCount,
            totalNotSmart,
            totalPhotoCamera,
            totalServiceable,
            totalSmart,
            totalSmartLampCountErrors,
            totalSmartLampCountSuccess,
            totalSmartTurnCountErrors,
            totalSmartTurnCountSuccess,
            totalVideoCamera,
            totalWarnings,
          } = data
          const devices = {
            totalErrors,
            totalModulesCount,
            totalNotSmart,
            totalPhotoCamera,
            totalServiceable,
            totalSmart,
            totalSmartLampCountErrors,
            totalSmartLampCountSuccess,
            totalSmartTurnCountErrors,
            totalSmartTurnCountSuccess,
            totalVideoCamera,
            totalWarnings,
          }
          const types = structuredClone(data.types)
          types.unshift({ id: 0, name: 'Все' })
          dispatch(footerSliceActions.setCounters(devices))
          dispatch(setTypeUnits(types))
        } catch (e) {
          console.log(e)
        }
      },
    }),
    getTypesUnitsForUser: build.query<TypeUnit[], void | { isNew?: boolean }>({
      query: () => ({
        url: '/devices/getUserDeviceTypes',
        method: 'GET',
      }),
      transformResponse: (
        res: { success: boolean; data: TypeUnit[] },
        meta,
        arg
      ) => {
        if (res.success && !arg?.isNew) {
          res.data.unshift({ id: 0, name: 'Все' })
        }
        return res.data
      },
    }),
  }),
  overrideExisting: false,
})

export const { useGetUnitsListMutation, useGetTypesUnitsForUserQuery } =
  newUnitsListService
