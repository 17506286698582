import { Dialog, notifyError, notifySuccess } from '@/components'
import UnitSelect from '@/modules/add-new-unit/components/UnitSelect/UnitSelect'
import {
  useAddNewUnitMutation,
  useGetNewUnitQuery,
} from '@/modules/add-new-unit/services/newUnitService'
import { AddNewUnitRes } from '@/modules/add-new-unit/types/newUnitType'
import { useGetDeviceDetailQuery } from '@/modules/device-module/services/deviceService'
import { UnitSearchReq } from '@/modules/devices-list/types/newUnitsType'
import { useGetUnitsHomeMapMutation } from '@/modules/maps/services/homeMapService'
import Input from '@/modules/organization/components/Input/Input'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { useGetUnitsTypesListQuery } from '@/services/unitsListService'
import { UserTypes } from '@/types/typeUser'
import Map from '@/components/Organisms/Map/Map'
import cn from '@/utils/cn'
import DeleteDeviceButton from '@/modules/device-module/components/deleteDeviceButton/deleteDeviceButton'
import { useChangeSet } from '@/utils/useChangeSet'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { DeviceType, SimpleDevicesIds, SmartDevicesIds } from '@/types/enums'
const s = cn('device-module-feature')

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Имя не может быть пустым')
    .trim('Имя не может быть пустым')
    .max(30, 'Поле должно содержать не более 30 символов'),
  address: Yup.string()
    .required('Адрес не может быть пустым')
    .trim('Адрес не может быть пустым')
    .max(250, 'Поле должно содержать не более 250 символов'),
  typeId: Yup.number()
    .required('Выберите систему')
    .typeError('Выберите систему'),
  imei: Yup.string()
    .required('IMEI-номер не может быть пустым')
    .test(
      'len',
      'IMEI-номер должен состоять из 15 или 16 цифр',
      (val) => val?.length === 15 || val?.length === 16
    )
    .typeError('IMEI-номер должен состоять из 15 или 16 цифр'),
})

export interface IDataFields {
  name: string
  address: string
  powerTypeId: number
  software: string
  hardware: string
  mac: string
  imei: string
  iccid: string
  typeId: number | ''
}

interface IUnitsFields {
  name: string
  address: string
  typeId: number | ''
  imei: string
  powerTypeId: number
}

const Features = () => {
  const defaultPoint = [47.06587193746529, 39.435380396518724]

  const { id } = useParams()

  const { data } = useGetDeviceDetailQuery(Number(id))
  const { data: unitsTypesData, isLoading: isLoadingUnitsTypesList } =
    useGetUnitsTypesListQuery(
      {
        isNew: true,
        isAll: true,
        isSmart: data?.smart,
        tsodd: data?.typeId === 5 || data?.typeId === 6,
      },
      { skip: !data }
    )

  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const { data: properties, refetch } = useGetNewUnitQuery(
    { id: Number(id) },
    {
      skip: currentUserType !== UserTypes.superAdmin,
    }
  )

  const [getDataUnits, { data: locations }] = useGetUnitsHomeMapMutation()
  const [updateProperties] = useAddNewUnitMutation()

  const [openDialogSave, setOpenDialogSave] = useState(false)
  const [loader, setLoader] = useState(false)
  const [newCoords, setNewCoords] = useState<number[]>(defaultPoint)
  const [center, setCenter] = useState<number[]>([47.2313, 39.7233])
  const refMark = useRef<any>(null)

  const onGetUnits = async () => {
    try {
      const usersSearchData: Omit<UnitSearchReq, 'page'> = {
        value: '',
        deviceTypeFilter: [0],
        deviceStateFilter: [0],
        favoriteId: 0,
      }
      await getDataUnits(usersSearchData).unwrap()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits().then()
  }, [])

  const defaultValues: IDataFields = {
    name: '',
    address: '',
    typeId: '',
    software: '',
    hardware: '',
    mac: '',
    imei: '',
    iccid: '',
    powerTypeId: 1,
  }

  const { handleSubmit, control, reset, trigger, formState, watch, getValues } =
    useForm<IDataFields>({
      defaultValues,
      resolver: yupResolver(validationSchema),
      criteriaMode: 'all',
      reValidateMode: 'onChange',
    })

  useEffect(() => {
    reset({
      name: properties?.data.name,
      address: properties?.data.address,
      software: properties?.data.software,
      hardware: properties?.data.hardware,
      mac: properties?.data.mac,
      imei: data?.smart ? properties?.data.imei : '000000000000000',
      iccid: properties?.data.iccid,
      typeId: properties?.data.typeId,
      powerTypeId: properties?.data.powerTypeId,
    })
    setNewCoords(() => [
      properties?.data.latitude ?? 47.2313,
      properties?.data.longitude ?? 39.7233,
    ])
    setCenter(() => [
      properties?.data.latitude ?? 47.2313,
      properties?.data.longitude ?? 39.7233,
    ])
  }, [properties])

  const onSaveBtnClick = () => {
    trigger().then(() => {
      if (
        !getValues('name') ||
        !getValues('address') ||
        !getValues('typeId') ||
        !getValues('imei')
      ) {
        return
      }
      if (
        !formState.errors.name &&
        !formState.errors.address &&
        !formState.errors.imei &&
        !formState.errors.typeId
      ) {
        setOpenDialogSave(true)
      }
    })
  }

  const onSubmit = async (dataForm: IUnitsFields) => {
    try {
      setOpenDialogSave(false)
      setLoader(true)
      const data: AddNewUnitRes = {
        id: Number(id),
        typeId: dataForm.typeId,
        name: dataForm.name,
        address: dataForm.address,
        coordinates: JSON.stringify(newCoords),
        imei: dataForm.imei,
        powerTypeId: dataForm.powerTypeId || 1,
      }
      const res = await updateProperties(data).unwrap()
      if (res.success) {
        notifySuccess('Данные успешно сохранены')
        refetch()
      }
    } catch (e) {
    } finally {
      setLoader(false)
    }
  }

  useChangeSet(() => {
    if (watch('name')?.length) {
      trigger('name')
    }
    if (watch('address')?.length) {
      trigger('address')
    }
    if (watch('typeId')) {
      trigger('typeId')
    }
    if (watch('imei')?.length) {
      trigger('imei')
    }
  }, [watch('name'), watch('address'), watch('typeId'), watch('imei')])
  const devicesList = unitsTypesData?.data.filter((device) => {
    if (
      data?.typeId === DeviceType.ksodd ||
      data?.typeId === DeviceType.kao ||
      data?.typeId === DeviceType.trafficLight
    ) {
      return SimpleDevicesIds.includes(device.id)
    } else {
      return SmartDevicesIds.includes(device.id)
    }
  })
  const handleChangeCoords = (coords: number[]) => {
    setNewCoords(coords)
  }
  return (
    <>
      <CircularProgress
        className={loader ? s('show-loader') : s('hide-loader')}
      />
      <Box
        flex={1}
        display='flex'
        flexDirection={{ xss: 'column', md: 'row' }}
        className={s('aqua', { loading: loader })}
      >
        <Dialog
          message='Сохранить внесенные изменения?'
          open={openDialogSave}
          handleClose={() => setOpenDialogSave(false)}
          handleAgree={() => {
            if (Object.keys(formState.errors)?.length >= 1) {
              notifyError('Исправте ошибки в форме')
            } else {
              handleSubmit(onSubmit)()
            }
          }}
        />
        {/*Блок карты*/}
        <Box
          flex={1}
          display='flex'
          border='1px solid #0072BC'
          className={s('map-container')}
          mt={{ xss: 0, lg: '35px', xlgx: '-48px' }}
          height={{
            mdx: '100%',
            lg: 'calc(100% - 35px)',
            xlgx: 'calc(100% + 48px)',
          }}
        >
          <Map
            center={newCoords}
            setNewCoords={handleChangeCoords}
            dataUnits={locations}
            newCoords={newCoords}
            draggable={true}
          />
        </Box>
        {/*Блок информации*/}
        <Box
          maxWidth={{ xss: '100%', md: 550 }}
          maxHeight={'calc(100vh - 245px)'}
          overflow={'auto'}
          display='flex'
          flex={{ xss: 1, xl4: 0.7, xll: 0.53 }}
        >
          <form
            style={{ width: '100%' }}
            onSubmit={(e) => e.preventDefault()}
          >
            <Box
              display='flex'
              flex={1}
              flexDirection={{ xss: 'column', md: 'column' }}
              pb={{ xss: 0.5, lg: 1 }}
            >
              {/*Версия*/}
              <Box
                flexDirection={{ xss: 'column', md: 'row' }}
                display={data?.smart ? 'flex' : 'none'}
              >
                <Box
                  flex={1}
                  px={1}
                  mt={0.5}
                >
                  <Typography
                    variant='h6'
                    fontSize={18}
                    color='primary'
                  >
                    Версия
                  </Typography>
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={1.5}
                  >
                    <Input
                      name='software'
                      type='text'
                      label='Software'
                      control={control}
                      disabled
                    />
                    <Input
                      name='hardware'
                      type='text'
                      label='Hardware'
                      control={control}
                      disabled
                    />
                    <Input
                      name='mac'
                      type='text'
                      label='MAC'
                      control={control}
                      disabled
                    />
                    <Input
                      name='imei'
                      type='number'
                      label='IMEI'
                      control={control}
                    />
                    <Input
                      name='iccid'
                      type='text'
                      label='ICCID'
                      control={control}
                      disabled
                    />
                  </Box>
                </Box>
              </Box>
              {/*Информация о системе*/}
              <Box
                display='flex'
                flexDirection={{ xss: 'column', xlg: 'row' }}
              >
                <Box
                  flex={1}
                  px={1}
                >
                  <Typography
                    variant='h6'
                    fontSize={18}
                    color='primary'
                  >
                    Информация о системе
                  </Typography>
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={1.5}
                  >
                    <UnitSelect
                      disabled={isLoadingUnitsTypesList}
                      items={devicesList}
                      name='typeId'
                      label='Выберите систему'
                      control={control}
                    />
                    {properties?.data.typeId !== 11 &&
                      properties?.data.typeId !== 6 &&
                      properties?.data.typeId !== 5 && (
                        <UnitSelect
                          items={[
                            { id: 1, name: '12В' },
                            { id: 2, name: '24В' },
                            { id: 3, name: '220B' },
                          ]}
                          name='powerTypeId'
                          label='Выберите систему питания'
                          control={control}
                        />
                      )}
                    <Input
                      name='name'
                      type='text'
                      label='Имя'
                      control={control}
                    />
                    <Input
                      name='address'
                      type='text'
                      label='Адрес'
                      control={control}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                display='flex'
                alignItems='flex-start'
                justifyContent='flex-end'
                gap={2}
                //height={{ xss: 40, lg: 60 }}
                mr={{ xss: 0.5, lg: 2 }}
                pt={1}
              >
                {currentUserType !== UserTypes.superAdmin ? null : (
                  <>{id && <DeleteDeviceButton deviceId={id} />}</>
                )}
                <LoadingButton
                  loading={false}
                  variant='outlined'
                  onClick={onSaveBtnClick}
                  disabled={
                    watch('name')?.trim()?.length === 0 ||
                    watch('address')?.trim()?.length === 0 ||
                    watch('imei')?.trim()?.length === 0 ||
                    watch('typeId') === '' ||
                    newCoords === defaultPoint
                  }
                >
                  <span>Сохранить</span>
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default Features
