import { setSelectedModulesId } from '@/modules/aqua-device-module/store/stateSlice'
import {
  addPhotoAfter,
  addPhotoBefore,
  setPhotos,
} from '@/modules/device-module/store/photoSliderSlice'
import { ditSliceActions } from '@/components/Organisms/DIT/store'
import { footerSliceActions } from '@/components/Organisms/Footer/slice'
import {
  removeCommand,
  removeItemDevice,
  removeItemDeviceMeteo,
  setAddDeleted,
  setClearDeleted,
  setDevice,
  setDeviceModules,
  setDeviceModulesMeteo,
  setDisabledSaveBtn,
  setFilters,
  setFromDate,
  setFromDateEvents,
  setSelected,
  setSelectedDevice,
  setSelectedMeteo,
  setSelectedModulesId as setSelectedModulesIdDevice,
  setSelectedTab,
  setToDate,
  toggleOnChangeDevice,
} from '@/modules/device-module/store/stateSlice'
import {
  removeCommandNew,
  setCommands,
  setLastUpdatedDate,
  setTerminalHistoryNew,
  triggerLoadHistory,
} from '@/modules/device-module/store/terminalNewSlice'
import { addOneTerminalHistory } from '@/modules/device-module/store/terminalSlice'
import { editNameFavorite } from '@/modules/favorites/store/favoritesSlise'
import {
  setIsLogin,
  setUser,
  setUserEmail,
} from '@/modules/login/store/userLoginStore'
import {
  deleteAll,
  deleteNotification,
  markAllAsRead,
  markAsRead,
} from '@/modules/sidebar/store/messagesSlice'
import {
  setIsChangeDescription,
  setIsChangeFeatures,
  setIsChangeModules,
  setIsChangeOrganization,
  setIsChangeUser,
  setIsOpenChangeDescriptionDialog,
  setIsOpenChangeFeaturesDialog,
  setIsOpenChangeModulesDialog,
  setIsOpenChangeOrganizationDialog,
  setIsOpenChangeUserDialog,
  setSaveFuncs,
} from '@/store/changeDialogSlise'
import {
  setFavoriteIdTanks,
  setFilterTanks,
  setIsSearchTanks,
  setSearchValueTanks,
  setTypeUnitsTanks,
} from '@/store/filterTanksSlise'
import { decrement, increment, incrementByAmount } from './counterSlise'
import {
  setFavoriteId,
  setFilter,
  setDeviceStateFilter,
  setModulesStateFilter,
  setModulesFilterValues,
  setIsSearch,
  setSearchValue,
  setSidebarWidth,
  toggleFScreen,
  toggleFScreenPhoto,
  toggleFullScreen,
  toggleFullScreenDetail,
} from './filterSystemsSlise'

export const actionCreators = {
  increment,
  decrement,
  incrementByAmount,
  setUserEmail,
  setUser,
  setIsLogin,
  setSearchValue,
  setFilter,
  setDeviceStateFilter,
  setModulesStateFilter,
  setModulesFilterValues,
  setIsSearch,
  setFavoriteId,
  toggleOnChangeDevice,
  setSelected,
  removeItemDevice,
  setDisabledSaveBtn,
  setFilters,
  setSelectedTab,
  setDevice,
  setDeviceModules,
  setFromDate,
  setToDate,
  setSelectedDevice,
  setAddDeleted,
  setClearDeleted,
  setSelectedMeteo,
  setDeviceModulesMeteo,
  removeItemDeviceMeteo,

  setSearchValueTanks,
  setIsSearchTanks,
  setFilterTanks,
  setFavoriteIdTanks,
  setTypeUnitsTanks,

  setCommands,
  removeCommand,
  setSelectedModulesId,
  setSelectedModulesIdDevice,
  toggleFullScreen,
  setPhotos,
  toggleFullScreenDetail,
  toggleFScreen,
  setIsChangeModules,
  setIsOpenChangeModulesDialog,
  setSaveFuncs,

  setIsChangeDescription,
  setIsOpenChangeDescriptionDialog,

  setIsChangeFeatures,
  setIsOpenChangeFeaturesDialog,
  setIsChangeUser,
  setIsOpenChangeUserDialog,
  setIsChangeOrganization,
  setIsOpenChangeOrganizationDialog,
  toggleFScreenPhoto,
  setSidebarWidth,

  markAsRead,
  deleteNotification,
  markAllAsRead,
  deleteAll,
  setFromDateEvents,
  addOneTerminalHistory,
  addPhotoBefore,
  addPhotoAfter,
  removeCommandNew,
  triggerLoadHistory,
  setTerminalHistoryNew,
  setLastUpdatedDate,
  editNameFavorite,
  ditReset: ditSliceActions.resetPrograms,
  ditSetDisabledPrograms: ditSliceActions.setDitDisabledPrograms,
  ditSetDitSize: ditSliceActions.setDitSize,
  ditSetProgramList: ditSliceActions.setProgramList,
  ditAddProgram: ditSliceActions.addProgram,
  ditRemoveProgram: ditSliceActions.removeProgram,
  ditAddSlide: ditSliceActions.addSlide,
  ditRemoveSlide: ditSliceActions.removeSlide,
  ditAddSlideItem: ditSliceActions.addSlideItem,
  ditUpdateSlideItemName: ditSliceActions.renameSlideItem,
  ditEditSlideItem: ditSliceActions.changeSlide,
  ditRemoveSlideItem: ditSliceActions.removeSlideItem,
  ditSetSlideItem: ditSliceActions.replaceSlideItem,
  ditSetSlideItems: ditSliceActions.replaceSlideItems,
  ditSetActiveProgram: ditSliceActions.setActiveProgram,
  ditUpdateProgramName: ditSliceActions.setProgramName,
  ditSetActiveSlide: ditSliceActions.setActiveSlide,
  ditUpdateSlideName: ditSliceActions.setSlideName,
  ditSetActiveSlideItem: ditSliceActions.setActiveSlideItem,
  ditSetUnsavedChanges: ditSliceActions.setUnsavedChanges,
  ditSetUnsavedTreeChanges: ditSliceActions.setUnsavedTreeChanges,
  ditSetActiveVariable: ditSliceActions.setDitActiveVariable,
  setFooterCounters: footerSliceActions.setCounters,
}
