import React, {
  type SyntheticEvent,
  type ChangeEvent,
  type FC,
  type ClipboardEvent,
  forwardRef,
} from 'react'
import styles from './DITbuttons.module.scss'
import { RichTextarea } from 'rich-textarea'
import Highlighter from 'react-highlight-words'
import { useActions } from '@/hooks'
import { notifyError } from '@/components'

interface Props {
  text: string
  onTextChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  variables: string[]
}

const renderHighlighter = (
  text: string,
  variables: string[],
  TagComponent: FC<{ children: string }>
) => (
  <Highlighter
    searchWords={variables}
    autoEscape={true}
    textToHighlight={text}
    highlightTag={TagComponent}
  />
)

const Tag: FC<{ children: string }> = ({ children }) => {
  const { ditSetActiveVariable } = useActions()

  const onTagClick = (e: SyntheticEvent<HTMLSpanElement>) => {
    ditSetActiveVariable(children)
  }

  return (
    <span
      className={styles.TextAreaTag}
      onClick={onTagClick}
    >
      {children}
    </span>
  )
}

const handlePastText = (e: ClipboardEvent<HTMLTextAreaElement>) => {
  const text = e.clipboardData.getData('Text')
  const regex = /\{[a-zA-Z][0-9]+\}/
  if (regex.test(text)) {
    e.preventDefault()
    notifyError('Ошибка. Ваш текст содержит переменные.')
  }
}

// eslint-disable-next-line react/display-name
export const DITTextarea = forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const richTextareaStyle = { width: '100%', height: '91px' }
    return (
      <RichTextarea
        ref={ref}
        className={styles.TextArea}
        value={props.text}
        style={richTextareaStyle}
        onChange={props.onTextChange}
        onPaste={handlePastText}
      >
        {(text) => renderHighlighter(text, props.variables, Tag)}
      </RichTextarea>
    )
  }
)

export default DITTextarea
