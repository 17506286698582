import { useActions, useAppSelector } from '@/hooks'
import {
  useGetDeviceDetailQuery,
  useGetPhotosDetailQuery,
} from '@/modules/device-module/services/deviceService'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'
import cn from '@/utils/cn'
import { Box, Tabs } from '@mui/material'
import Tab from '@mui/material/Tab'
import * as React from 'react'
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ActionsIcons from './ActionsIcons'

const s = cn('device-tabs')

const Actions = () => {
  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const { id } = useParams()
  const { pathname, state } = useLocation()

  const { selectedTab } = useAppSelector(stateDeviceSelector)
  const { setSelectedTab, setFilters } = useActions()

  const { data } = useGetDeviceDetailQuery(Number(id), {
    skip: pathname.includes('photos'),
  })
  const { data: photosData } = useGetPhotosDetailQuery(Number(id), {
    skip: !pathname.includes('photos'),
  })

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
    setFilters([0])
  }

  useEffect(() => {
    if (
      data &&
      !data.smart &&
      data?.typeId !== 7 &&
      data?.typeId !== 8 &&
      data?.typeId !== 9
    ) {
      if (currentUserType !== UserTypes.superAdmin) {
        if (data?.typeId === 7 || data?.typeId === 8 || data?.typeId === 9) {
          setSelectedTab(4)
        } else {
          setSelectedTab(3)
        }
      } else {
        setSelectedTab(4)
      }
    } else if (state && state.eventId && state.deviceId) {
      setSelectedTab(2)
    } else {
      setSelectedTab(selectedTab)
    }
  }, [data, id])
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      flexDirection={{ xss: 'column-reverse', xlg: 'row' }}
      alignItems={{ xss: 'center', xlg: 'flex-start' }}
      gap={{ xss: 0, lg: 0 }}
    >
      <ActionsIcons />
      <Box
        sx={{
          maxWidth: {
            xss: 300,
            xs: 360,
            sm: 420,
            md: 500,
            lg: 500,
            xlg: 560,
            xl3: 750,
          },
        }}
      >
        <Tabs
          className={s()}
          value={selectedTab}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          <Tab
            label={
              data?.typeId === 4
                ? 'Датчики'
                : data?.typeId === 7 ||
                    data?.typeId === 8 ||
                    photosData?.typeId === 7
                  ? 'Изображение'
                  : 'Устройства'
            }
            sx={{
              display:
                data?.smart ||
                data?.typeId === 7 ||
                data?.typeId === 8 ||
                data?.typeId === 9 ||
                photosData?.typeId === 7
                  ? 'block'
                  : 'none',
              lineHeight: 1.7,
            }}
          />
          <Tab
            label='Прогноз'
            sx={{
              display: 'none',
              lineHeight: 1.7,
            }}
          />
          <Tab
            label='События'
            sx={{
              display:
                data?.smart ||
                data?.typeId === 7 ||
                data?.typeId === 9 ||
                data?.typeId === 8 ||
                photosData?.typeId === 7
                  ? 'block'
                  : 'none',
              lineHeight: 1.7,
            }}
          />
          <Tab label='Описание' />
          <Tab
            label='Свойства'
            sx={{
              display:
                currentUserType !== UserTypes.superAdmin ? 'none' : 'block',
              lineHeight: 1.7,
            }}
          />
          <Tab
            label='Терминал'
            sx={{
              display:
                data?.smart && currentUserType === UserTypes.superAdmin
                  ? 'block'
                  : 'none',
              lineHeight: 1.7,
            }}
          />
        </Tabs>
      </Box>
    </Box>
  )
}

export default Actions
