import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { OrganizationInList } from '../../types/organizationType'
import UnitRow from '@/modules/organization/components/Units/UnitRow'
import {
  useAddUnitsMutation,
  useDeleteUnitsMutation,
  useSearchUnitsOrganizationMutation,
} from '@/modules/organization/services/organizationService'
import cn from '@/utils/cn'
import styles from '../Units.module.scss'
import {
  Checkbox,
  CircularProgress,
  Pagination,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'

const classNames = cn('units')

const containerHeightIphone = (height: number) => height - 250
const containerHeightTablet = 380
const containerHeightDefault = 290

const getHeight = (isIphone: boolean, isTablet: boolean) => {
  if (isIphone) {
    return `calc(100vh - ${containerHeightIphone(document.documentElement.clientHeight)}px)`
  }
  return `calc(100vh - ${isTablet ? containerHeightTablet : containerHeightDefault}px)`
}

interface IUnitsTable {
  isSearch: boolean
  searchValue: string
  filter: number[]
  setSearchValue: Dispatch<SetStateAction<string>>
  setLoader: Dispatch<SetStateAction<boolean>>
}

const UnitsTable: FC<IUnitsTable> = ({
  isSearch,
  searchValue,
  filter,
  setSearchValue,
  setLoader,
}) => {
  const { id } = useParams()
  const [units, setUnits] = useState<OrganizationInList[]>([])
  const allUnitsSelected = units.every((unit) => unit.selected)
  const allUnitsDisabled = units.every((unit) => !unit.selected)
  const [addUnitsService, { isLoading: isLoadingAdd, isError: isErrorAdd }] =
    useAddUnitsMutation()
  const [
    deleteUnitsService,
    { isLoading: isLoadingDelete, isError: isErrorDelete },
  ] = useDeleteUnitsMutation()
  const [page, setPage] = useState(
    Number(localStorage.getItem('pageOrgUnits')) || 1
  )
  const [pages, setPages] = useState(1)
  const [count, setCount] = useState(0)
  const [checkAll, setCheckAll] = useState(false)

  const addUnits = async (deviceIds: number[]) => {
    await addUnitsService({
      organizationId: Number(id),
      deviceIds: deviceIds,
    }).unwrap()
  }
  const removeUnits = async (deviceIds: number[]) => {
    await deleteUnitsService({
      organizationId: Number(id),
      deviceIds: deviceIds,
    }).unwrap()
  }

  const toggleCheckAll = async () => {
    if (units.length) {
      const deviceIds = units.map((unit) => unit.deviceId)
      checkAll ? await removeUnits(deviceIds) : await addUnits(deviceIds)
      setCheckAll(!checkAll)
    }
  }

  useEffect(() => {
    if (isErrorDelete || isErrorAdd) {
      setCheckAll((prevCheckAll) => !prevCheckAll)
    }
  }, [isErrorDelete, isErrorAdd])

  const [getUnitsService, { isLoading: isLoadingSearch }] =
    useSearchUnitsOrganizationMutation()

  const handleChangePage = (event: unknown, newPage: number) => {
    localStorage.setItem('pageOrgUnits', String(newPage))
    setPage(newPage)
  }

  const onGetUnits = async () => {
    try {
      const unitsSearchData = {
        value: !searchValue.trim().length ? '' : searchValue,
        deviceTypeFilter: filter,
        page: page,
        organizationId: Number(id),
      }
      const res = await getUnitsService(unitsSearchData).unwrap()
      if (res.success) {
        setCheckAll(res.data.every((unit) => unit.selected))
        setUnits(res.data)
        setPage(res.page || 1)
        setPages(res.pages || 1)
        setCount(res.count ?? 0)
      }
      if (!searchValue.trim().length) {
        setSearchValue('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits()
  }, [page, isSearch, filter, checkAll])

  const isTablet = useMediaQuery('(max-width:870px)')
  const isIphone = navigator.userAgent.includes('iPhone')

  return (
    <>
      <div className={styles.CheckBoxSelectAll}>
        <Checkbox
          disabled={isLoadingAdd || isLoadingDelete}
          color='primary'
          checked={checkAll}
          indeterminate={!allUnitsSelected && !allUnitsDisabled}
          onChange={toggleCheckAll}
        />
      </div>
      <CircularProgress
        className={
          isLoadingSearch || isLoadingAdd || isLoadingDelete
            ? 'show-loader'
            : 'hide-loader'
        }
      />
      <Paper
        sx={{ width: '100%', mt: 3, pb: 2 }}
        elevation={3}
        className={classNames({ loading: isLoadingSearch })}
      >
        <TableContainer
          sx={{ height: getHeight(isIphone, isTablet), overflowY: 'auto' }}
          className={classNames('tableContainer')}
        >
          <Table size='small'>
            <TableBody>
              {units.length === 0 ? (
                <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                  >
                    <Typography textAlign='center'>
                      Ни одной записи не найдено
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                units.map((unit) => (
                  <UnitRow
                    key={unit.deviceId}
                    unit={unit}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          mt={2}
          display='flex'
          width='100%'
          justifyContent='flex-end'
        >
          {count > 25 && (
            <Pagination
              count={pages}
              page={page}
              onChange={handleChangePage}
              size='small'
              siblingCount={1}
              boundaryCount={1}
              color='primary'
            />
          )}
        </Box>
      </Paper>
    </>
  )
}

export default UnitsTable
