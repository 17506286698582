import React, {
  Dispatch,
  FC,
  SetStateAction,
  useLayoutEffect,
  useState,
} from 'react'
import { Box } from '@mui/material'
import { useDebounce } from '@/hooks'
import { useChangeSet } from '@/utils/useChangeSet'
import cn from '@/utils/cn'
import UnitsHeader from './UnitsHeader'
import UnitsTable from './UnitsTable'

const s = cn('units')

interface IUnits {
  setLoader: Dispatch<SetStateAction<boolean>>
}

const Units: FC<IUnits> = ({ setLoader }) => {
  const [searchValue, setSearchValue] = useState(
    localStorage.getItem('searchValueUserUnits') ?? ''
  )
  const [isSearch, setIsSearch] = useState(false)
  const [filter, setFilter] = useState<number[]>(
    (localStorage.getItem('filterValueUserUnits') &&
      JSON.parse(localStorage.getItem('filterValueUserUnits') ?? '')) ?? [0]
  )
  const handledSearchStr = searchValue.replace(/^ +|( )(?=\1)| +$/g, '')
  const debouncedSearch = useDebounce(handledSearchStr, 3000)

  useChangeSet(() => {
    setIsSearch((prevState) => !prevState)
  }, debouncedSearch)

  useChangeSet(() => {
    localStorage.setItem('searchValueUserUnits', searchValue)
  }, [searchValue])

  useLayoutEffect(() => {
    window.addEventListener('beforeunload', removeLocalStoradge)
    return () => {
      window.removeEventListener('beforeunload', removeLocalStoradge)
    }
  }, [])

  const removeLocalStoradge = (e: any) => {
    e.preventDefault()
    localStorage.removeItem('searchValueUserUnits')
    localStorage.removeItem('pageUserUnits')
    localStorage.removeItem('filterValueUserUnits')
  }

  return (
    <Box className={s()}>
      <UnitsHeader
        filter={filter}
        setFilter={setFilter}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setIsSearch={setIsSearch}
      />
      <UnitsTable
        isSearchActive={isSearch}
        searchValue={searchValue}
        deviceTypeFilter={filter}
        updateSearchValue={setSearchValue}
        updateLoader={setLoader}
      />
    </Box>
  )
}

export default Units
