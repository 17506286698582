import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import {
  useAddUnitsUserMutation,
  useDeleteUnitsUserMutation,
  useSearchUnitsUserMutation,
} from '@/modules/user/services/userService'
import { UnitsInListUser } from '@/modules/user/types/userType'
import cn from '@/utils/cn'
import {
  Checkbox,
  CircularProgress,
  Pagination,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import styles from './Units.module.scss'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { useParams } from 'react-router-dom'
import UnitRow from './UnitRow'

const RESPONSIVE_MAX_WIDTH = '(max-width:870px)'
const LOCAL_STORAGE_PAGE_KEY = 'pageUserUnits'
const CLASS_NAME = cn('units')

interface UnitsTableProps {
  isSearchActive: boolean
  searchValue: string
  deviceTypeFilter: number[]
  updateSearchValue: Dispatch<SetStateAction<string>>
  updateLoader: Dispatch<SetStateAction<boolean>>
}

const UnitsTable: FC<UnitsTableProps> = ({
  isSearchActive,
  searchValue,
  deviceTypeFilter,
  updateSearchValue,
  updateLoader,
}) => {
  const { id } = useParams()
  const [units, setUnits] = useState<UnitsInListUser[]>([])
  const [currentPage, setCurrentPage] = useState(
    () => Number(localStorage.getItem(LOCAL_STORAGE_PAGE_KEY)) || 1
  )
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  const [addUnitsService, { isLoading: isAdding, isError: addError }] =
    useAddUnitsUserMutation()
  const [deleteUnitsService, { isLoading: isDeleting, isError: deleteError }] =
    useDeleteUnitsUserMutation()
  const [getUnitsService, { isLoading: isSearching }] =
    useSearchUnitsUserMutation()

  const allUnitsSelected = units.every((unit) => unit.selected)
  const allUnitsDeselected = units.every((unit) => !unit.selected)
  const [selectAll, setSelectAll] = useState(false)

  const handlePageChange = (event: unknown, newPage: number) => {
    localStorage.setItem(LOCAL_STORAGE_PAGE_KEY, String(newPage))
    setCurrentPage(newPage)
  }

  const addAllUnits = async (deviceIds: number[]) => {
    await addUnitsService({ userId: Number(id), deviceIds }).unwrap()
  }

  const deleteAllUnits = async (deviceIds: number[]) => {
    await deleteUnitsService({ userId: Number(id), deviceIds }).unwrap()
  }

  const toggleAllUnitsSelection = async () => {
    if (units.length) {
      const deviceIds = units.map((unit) => unit.deviceId)
      selectAll ? await deleteAllUnits(deviceIds) : await addAllUnits(deviceIds)
      setSelectAll(!selectAll)
    }
  }

  useEffect(() => {
    if (deleteError || addError) {
      setSelectAll(!selectAll)
    }
  }, [deleteError, addError])

  const fetchUnits = async () => {
    try {
      const searchParams = {
        value: searchValue.trim().length ? searchValue : '',
        deviceTypeFilter: deviceTypeFilter,
        page: currentPage,
        userId: Number(id),
      }
      const response = await getUnitsService(searchParams).unwrap()
      if (response.success) {
        setSelectAll(response.data.every((unit) => unit.selected))
        setUnits(response.data)
        setCurrentPage(response.page || 1)
        setTotalPages(response.pages || 1)
        setTotalCount(response.count ?? 0)
      }
      if (!searchValue.trim().length) {
        updateSearchValue('')
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchUnits()
  }, [currentPage, isSearchActive, deviceTypeFilter, selectAll])

  const isTablet = useMediaQuery(RESPONSIVE_MAX_WIDTH)
  const isIphone = navigator.userAgent.includes('iPhone')

  return (
    <>
      <div className={styles.CheckBoxSelectAll}>
        <Checkbox
          disabled={isAdding || isDeleting}
          color='primary'
          checked={selectAll}
          indeterminate={!allUnitsSelected && !allUnitsDeselected}
          onChange={toggleAllUnitsSelection}
        />
      </div>
      <CircularProgress
        className={
          isSearching || isAdding || isDeleting ? 'show-loader' : 'hide-loader'
        }
      />
      <Paper
        sx={{ width: '100%', mt: 3, pb: 2 }}
        elevation={2}
        className={CLASS_NAME({ loading: isSearching })}
      >
        <TableContainer
          sx={{
            height: `calc(100vh - ${
              isIphone
                ? document.documentElement.clientHeight - 250
                : isTablet
                  ? 380
                  : 290
            }px)`,
            overflowY: 'auto',
          }}
          className={CLASS_NAME('tableContainer')}
        >
          <Table size='small'>
            <TableBody>
              {units.length === 0 ? (
                <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                  >
                    <Typography textAlign='center'>No records found</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                units.map((unit) => (
                  <UnitRow
                    key={unit.deviceId}
                    unit={unit}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          mt={2}
          display='flex'
          width='100%'
          justifyContent='flex-end'
        >
          {totalCount > 25 && (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              size='small'
              siblingCount={1}
              boundaryCount={1}
              color='primary'
            />
          )}
        </Box>
      </Paper>
    </>
  )
}

export default UnitsTable
