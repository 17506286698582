import { api } from '@/api'
import {
  AddUnitReq,
  ISimpleResponseUpdateOrganization,
  OrganizationTypeRes,
  UnitsGetReq,
  UnitsListRes,
} from '@/modules/organization/types/organizationType'
import { ISimpleResponse } from '@/modules/login/types'
import { IOrganizationDataFields } from '@/modules/organization/components/OrganizationData/OrganizationData'

const organizationService = api.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationProfile: build.query<OrganizationTypeRes, string>({
      query: (id) => ({
        url: '/organizations/get',
        method: 'GET',
        params: {
          id,
        },
      }),
      providesTags: (result, error, id) => {
        return [{ type: 'Organizations', id }]
      },
    }),
    changeOrganizationStatus: build.mutation<
      ISimpleResponse,
      {
        id: number
        status: boolean
      }
    >({
      query: ({ id, status }) => ({
        url: '/organizations/updateActive',
        method: 'POST',
        params: {
          id,
          status,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Organizations', id: arg.id },
      ],
    }),
    updateOrganizationProfile: build.mutation<
      ISimpleResponseUpdateOrganization,
      {
        data: IOrganizationDataFields
        id: number
      }
    >({
      query: ({ data, id }) => ({
        url: '/organizations/update',
        method: 'POST',
        body: { id, ...data },
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'Organizations', id: arg.id }]
      },
    }),
    searchUnitsOrganization: build.mutation<UnitsListRes, UnitsGetReq>({
      query: (body) => ({
        url: '/organizations/getDevices',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'Organizations' }]
      },
    }),
    addUnits: build.mutation<ISimpleResponse, AddUnitReq>({
      query: (body) => ({
        url: '/organizations/temp/addDevice',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'Organizations' }]
      },
    }),
    deleteUnits: build.mutation<ISimpleResponse, AddUnitReq>({
      query: (body) => ({
        url: '/organizations/temp/deleteDevice',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'Organizations' }]
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetOrganizationProfileQuery,
  useUpdateOrganizationProfileMutation,
  useChangeOrganizationStatusMutation,
  useSearchUnitsOrganizationMutation,
  useAddUnitsMutation,
  useDeleteUnitsMutation,
} = organizationService
