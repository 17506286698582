import { useActions, useAppSelector } from '@/hooks'
import {
  filterSystemSelector,
  fScreenSelector,
} from '@/store/filterSystemsSlise'
import cn from '@/utils/cn'
import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import * as React from 'react'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useGetUnitsListMutation } from '../../services/newUnitsListService'
import { Unit, UnitSearchReq } from '../../types/newUnitsType'
import GridView from './GridView'
import TableView from './TableView'
const s = cn('devices-list')

interface IUsersTable {
  isGridView: boolean
  columns: number
  setIsFullScreen: Dispatch<SetStateAction<boolean>>
  setIsShowGridBtn: Dispatch<SetStateAction<boolean>>
  setColumns: Dispatch<SetStateAction<number>>
}

const DevicesTable: FC<IUsersTable> = ({
  isGridView,
  columns,
  setColumns,
  setIsShowGridBtn,
  setIsFullScreen,
}) => {
  const { filter, searchValue, isSearch, favoriteId, deviceStateFilter } =
    useAppSelector(filterSystemSelector)
  const { setSearchValue, setSelectedTab } = useActions()

  const [getUnitsService, { isLoading: isLoadingSearch }] =
    useGetUnitsListMutation()

  const [units, setUnits] = useState<Unit[]>([])
  const [page, setPage] = React.useState(
    Number(localStorage.getItem('pageDevices')) || 1
  )
  const [pages, setPages] = React.useState(1)
  const [count, setCount] = React.useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    localStorage.setItem('pageDevices', String(newPage))
    setPage(newPage)
  }

  const onGetUnits = async () => {
    try {
      const usersSearchData: UnitSearchReq = {
        value: !searchValue.trim().length ? '' : searchValue,
        page: page,
        deviceTypeFilter: filter,
        deviceStateFilter: deviceStateFilter,
        favoriteId: favoriteId,
      }
      const res = await getUnitsService(usersSearchData).unwrap()
      if (res.success) {
        setUnits(res.data)
        setPage(res.page || 1)
        setPages(res.pages || 1)
        setCount(res.count ?? 0)

        const cameras = res.data.filter(
          (item) => item.typeId === 7 || item.typeId === 8
        )
        if (cameras.length) {
          setIsShowGridBtn(true)
        } else {
          setIsShowGridBtn(false)
        }
      }
      if (!searchValue.trim().length) {
        setSearchValue('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits().then()
    setSelectedTab(0)
  }, [page, isSearch, deviceStateFilter])

  const { isFullScreen: isFullScreenGlobal } =
    useAppSelector(filterSystemSelector)

  const isFullScreen = useAppSelector(fScreenSelector)

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      position={
        isFullScreen ? 'unset' : isFullScreenGlobal ? 'unset' : 'relative'
      }
      className={s('container')}
    >
      <CircularProgress
        sx={{ zIndex: 100 }}
        className={isLoadingSearch ? s('show-loader') : s('hide-loader')}
      />
      <Box
        width='100%'
        height='100%'
        display='flex'
        flexDirection='column'
        className={isLoadingSearch ? s('table-loading') : ''}
      >
        <Paper
          sx={{
            flex: 1,
            width: '100%',
            height: '100%',
            pb: { xss: 0, md: isFullScreen ? 0 : 1 },
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.83)',
          }}
          elevation={6}
          className={s({ fullScreen: isFullScreen })}
        >
          {isGridView ? (
            <TableView
              units={units}
              count={count}
              pages={pages}
              page={page}
              handleChangePage={handleChangePage}
            />
          ) : (
            <GridView
              units={units}
              columns={columns}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              setColumns={setColumns}
            />
          )}
        </Paper>
      </Box>
    </Box>
  )
}

export default DevicesTable
