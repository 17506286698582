import { Dialog, notifyError, notifySuccess } from '@/components'
import UnitSelect from '@/modules/add-new-unit/components/UnitSelect/UnitSelect'
import { useAddNewDitUnitMutation } from '@/modules/add-new-unit/services/newUnitService'
import Input from '@/modules/organization/components/Input/Input'
import { UserTypes } from '@/types/typeUser'
import TimePicker from '@/modules/aqua-device-module/components/actions/ui/TimePicker'
import DeleteDeviceButton from '@/modules/device-module/components/deleteDeviceButton/deleteDeviceButton'
import { useChangeSet } from '@/utils/useChangeSet'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Map from '@/components/Organisms/Map/Map'
import { LoaderFullScreen } from '@/ui'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Название не может быть пустым')
    .trim('Название не может быть пустым')
    .max(30, 'Поле должно содержать не более 30 символов'),
  ip: Yup.string()
    .required('IP Адрес не может быть пустым')
    .trim('IP Адрес не может быть пустым')
    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: 'Недопустимые символы',
      excludeEmptyString: true,
    })
    .test('ip', 'Адрес имеет некорректный формат', (value) => {
      return value === undefined || value.trim() === ''
        ? true
        : value.split('.').find((i) => parseInt(i, 10) > 255) === undefined
    })
    .max(15, 'IP Адрес должен содержать не более 15 символов'),

  size: Yup.string().required('Укажите размер'),
  port: Yup.number()
    .required('Порт не может быть пустым')
    .typeError('Недопустимые символы')
    .moreThan(1, 'Порт не может быть пустым')
    .lessThan(99999, 'Порт должен содержать не более 5 символов'),
  address: Yup.string()
    .required('Адрес не может быть пустым')
    .trim('Адрес не может быть пустым')
    .max(250, 'Поле должно содержать не более 250 символов'),
})

export type MutateDitReq = {
  id: number
  name: string
  typeId: number
  address: string
  coordinates: string
  ip: string
  port: number
  brightness: number
  sizeTypeId: number
  brightnessEndTime: string
  brightnessStartTime: string
}

export interface IDataFields {
  name: string
  ip: string
  port: number
  address: string
  size: string
  brightness: number
  coordinates: number[]
}

interface IUnitsFields {
  name: string
  ip: string
  port: number
  address: string
  size: string
  brightness: number
  coordinates: number[]
}

const BRIGHTNESS: { id: number; name: string }[] = []
for (let i = 1; i <= 10; i++) {
  BRIGHTNESS.push({ id: i * 10, name: `${i * 10}%` })
}

type Props = {
  formValues: any
  locations: any
  refetch: () => void
  currentUserType: number
}
const FeaturesDitForm: React.FC<Props> = ({
  formValues,
  currentUserType,
  refetch,
  locations,
}) => {
  const {
    name,
    address,
    latitude,
    longitude,
    port,
    ip,
    brightness,
    sizeType,
    sizeTypeId,
    typeId,
    brightnessEndTime,
    brightnessStartTime,
  } = formValues

  const { id } = useParams()
  const [updateProperties, { isLoading }] = useAddNewDitUnitMutation()
  const [openDialogSave, setOpenDialogSave] = useState(false)
  const [timeStart, setTimeStart] = useState('')
  const [timeEnd, setTimeEnd] = useState('')
  const [loader, setLoader] = useState(false)
  const [newCoords, setNewCoords] = useState<number[]>([latitude, longitude])
  const [formChanged, setFormChanged] = useState(false)

  const defaultValues: IDataFields = {
    name,
    address,
    size: sizeType,
    coordinates: [latitude, longitude],
    port,
    ip,
    brightness,
  }
  const { handleSubmit, control, trigger, formState, watch } =
    useForm<IDataFields>({
      defaultValues,
      resolver: yupResolver(validationSchema),
      criteriaMode: 'all',
      reValidateMode: 'onChange',
    })

  const handleChangeCoords = (coords: number[]) => {
    setFormChanged(true)
    setNewCoords(coords)
  }

  const onSaveBtnClick = () => {
    // if (!Object.keys(formState.touchedFields).length || !formState.isDirty) {
    //   setOpenDialogSave(true)
    // } else {
    //   notifyError('Исправте ошибки в форме')
    // }
    setOpenDialogSave(true)
  }

  useChangeSet(() => {
    trigger().then((hasErrors: boolean) => {
      if (hasErrors) {
        setFormChanged(true)
      } else {
        setFormChanged(false)
      }
    })
  }, [
    watch('name'),
    watch('address'),
    watch('ip'),
    watch('port'),
    watch('brightness'),
  ])

  const onSubmit = async (dataForm: IUnitsFields) => {
    try {
      setOpenDialogSave(false)
      setLoader(true)
      const reqData: MutateDitReq = {
        id: Number(id),
        name: dataForm.name,
        typeId: typeId,
        address: dataForm.address,
        coordinates: JSON.stringify(newCoords),
        ip: dataForm.ip,
        port: dataForm.port,
        brightness: dataForm.brightness,
        sizeTypeId,
        brightnessEndTime: timeStart || brightnessStartTime,
        brightnessStartTime: timeEnd || brightnessEndTime,
      }
      const res = await updateProperties(reqData).unwrap()
      if (res.success) {
        notifySuccess('Данные успешно сохранены')
        refetch()
      }
    } finally {
      setLoader(false)
    }
  }

  const handleChangeTime = (timeStart: string, timeEnd: string) => {
    setFormChanged(true)
    setTimeStart(timeStart)
    setTimeEnd(timeEnd)
  }
  return (
    <>
      <LoaderFullScreen open={isLoading} />
      <Dialog
        message='Сохранить внесенные изменения?'
        open={openDialogSave}
        handleClose={() => setOpenDialogSave(false)}
        handleAgree={handleSubmit(onSubmit)}
      />
      <Box
        flex={1}
        display='flex'
        flexDirection={{ xss: 'column', md: 'row' }}
      >
        <Box
          flex={1}
          display='flex'
          border='1px solid #0072BC'
          mt={{ xss: 0, lg: '35px', xlgx: '-48px' }}
          height={{
            mdx: '100%',
            lg: 'calc(100% - 35px)',
            xlgx: 'calc(100% + 48px)',
          }}
        >
          <Map
            center={newCoords}
            setNewCoords={handleChangeCoords}
            dataUnits={locations}
            newCoords={newCoords}
            draggable={true}
          />
        </Box>
        <Box
          maxWidth={{ xss: '100%', md: 550 }}
          maxHeight={'calc(100vh - 245px)'}
          overflow={'auto'}
          display='flex'
          flex={{ xss: 1, xl4: 0.7, xll: 0.53 }}
        >
          <form
            style={{ width: '100%' }}
            onSubmit={(e) => e.preventDefault()}
          >
            <Box
              display='flex'
              flex={1}
              flexDirection={{ xss: 'column', md: 'column' }}
              pb={{ xss: 0.5, lg: 1 }}
            >
              <Box
                display='flex'
                flexDirection={{ xss: 'column', xlg: 'row' }}
              >
                <Box
                  flex={1}
                  px={1}
                >
                  <Typography
                    variant='h6'
                    fontSize={18}
                    color='primary'
                  >
                    Информация о системе
                  </Typography>
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={1.5}
                  >
                    <Input
                      name='name'
                      type='text'
                      label='Имя'
                      control={control}
                    />
                    <Input
                      name='address'
                      type='text'
                      label='Адрес'
                      control={control}
                    />
                    <Input
                      name='size'
                      disabled={true}
                      type='text'
                      label='Размер'
                      control={control}
                    />
                    <Input
                      name='ip'
                      type='text'
                      label='IP адрес'
                      control={control}
                    />
                    <Input
                      name='port'
                      type='number'
                      label='Порт'
                      control={control}
                    />
                    <UnitSelect
                      items={BRIGHTNESS}
                      name='brightness'
                      label='Яркость'
                      control={control}
                    />
                    <TimePicker
                      brightnessEndTime={brightnessEndTime}
                      brightnessStartTime={brightnessStartTime}
                      onChange={handleChangeTime}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                display='flex'
                alignItems='flex-start'
                justifyContent='flex-end'
                gap={2}
                mr={{ xss: 0.5, lg: 2 }}
                pt={1}
              >
                {currentUserType !== UserTypes.superAdmin ? null : (
                  <>{id && <DeleteDeviceButton deviceId={id} />}</>
                )}
                <LoadingButton
                  variant='outlined'
                  onClick={onSaveBtnClick}
                  disabled={!formChanged}
                >
                  <span>Сохранить</span>
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default FeaturesDitForm
