import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type DevicesCount = {
  totalErrors?: number
  totalModulesCount?: number
  totalNotSmart?: number
  totalPhotoCamera?: number
  totalServiceable?: number
  totalSmart?: number
  totalSmartLampCountErrors?: number
  totalSmartLampCountSuccess?: number
  totalSmartTurnCountErrors?: number
  totalSmartTurnCountSuccess?: number
  totalVideoCamera?: number
  totalWarnings?: number
}

const initialState: { counters: DevicesCount } = {
  counters: {
    totalErrors: 0,
    totalWarnings: 0,
    totalModulesCount: 0,
    totalNotSmart: 0,
    totalPhotoCamera: 0,
    totalServiceable: 0,
    totalSmart: 0,
    totalSmartLampCountErrors: 0,
    totalSmartLampCountSuccess: 0,
    totalSmartTurnCountErrors: 0,
    totalSmartTurnCountSuccess: 0,
    totalVideoCamera: 0,
  },
}

const footerSlice = createSlice({
  name: 'footerSlice',
  initialState,
  reducers: {
    setCounters: (state, action: PayloadAction<DevicesCount>) => {
      state.counters = action.payload
    },
  },
})

export const footerSelector = (state: RootState) =>
  state.footerSliceReducer.counters

export const footerSliceReducer = footerSlice.reducer
export const footerSliceActions = footerSlice.actions
